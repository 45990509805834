import React, { Component } from 'react'
import { IBlock } from "../../framework/src/IBlock";
import { layout_Corners_Dashboard, search_Icon, natasha_Icon, retouch_Graph, group_Logo, logout_icon, dashboard_Icon, explorer_Icon, trial_Icon } from "./Assets"

import { Message } from "../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

import { BlockComponent } from "../../framework/src/BlockComponent";



export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  iframeData: string | null;
  loading: boolean;
  IframeURL: string | null;
  IframeName: string | null;
  ready: boolean,
  // Customizable Area End
}
interface SS { }
export default class Iframe extends BlockComponent<Props, S, SS>{
  iframRef: any;
  goodDataAPIId: string = "";
  dashboardCardDataAPIId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.iframRef = React.createRef();

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      iframeData: null,
      loading: false,
      IframeURL: null,
      IframeName: null,
      ready: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
  const path = window.location.pathname;
  // if(path === prevProps.navigation)
    const match = path.match(/(\d+)$/);
    if(match && match[1] === prevProps.navigation.getParam('id')) return;
    this.checkdata(match)
}
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    //get the id here
    const path = window.location.pathname;
    const match = path.match(/(\d+)$/);
    this.checkdata(match)
    const storedTrialNameData = localStorage.getItem('trialName');

    if (storedTrialNameData === 'Explorer') {
      this.setState({ loading: true })
      this.callIfram()
    }
    if (storedTrialNameData === 'Qlik Cloud Trial') {
      this.getSrc()
    }
    // Customizable Area End
  }

  getSrc = async () => {
    const res = await fetch("https://qlik.builder.ai/jwt/hub/my/work", {
      method: 'GET',
      headers: {
        "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJkYXZpZC5hY2hlc29uQGJ1aWxkZXIuYWkiLCJ1c2VyRGlyZWN0b3J5IjoiR09PR0xFIn0.O_0GAfdfzd9rq6rxra3_NZ1nNt-yB0XvTnCoL1LJKQgR8qp4jtmcEKPY9o2RqynSzdbpWxaPDEnD9GQ_xnmVL__m27kcm78Tr5ENoNFca3uIeZsDdpoXyQRcOpJpXfsfaYe562yXlFC0xWHN-rJkP64U4fMslHzaH_GjUsyiWsot9vzhAKXJAP-Ux10SUNwVSqw0zfGDIi4sHkST3kWEK8UPTxOIsoe_dAys96IkwcArz8lBZ18JbKm7yCidi8iv0FWUcneo8md-gkCmB8nER4uLRj0I5Y70IRCW5m3wmH5sF1mm1RzTXH8DxuNUEYgYL8Ue-D3HqcH0wq0Qllcamw"
      }
    });
    const blob = await res.blob();
    const urlObject = URL.createObjectURL(blob);
    this.setState({ iframeData: urlObject });
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (webApiRequestCallId === this.goodDataAPIId) {
        this.callIfram()
      };
      this.handleGetDashboardCardData(webApiRequestCallId ,webResponseJson)
    }
    // Customizable Area End
  }

  handleGetDashboardCardData = (webApiRequestCallId: any, webResponseJson: any)=> {
    if(webApiRequestCallId === this.dashboardCardDataAPIId){
      this.setState({ready: true, IframeURL: webResponseJson.data.attributes.url, IframeName: webResponseJson.data.attributes.name})
    }
  }
  checkdata = (match: RegExpMatchArray | null)=>{
    if(match){
      const id = match[1]
      const localStorageUserToken = localStorage.getItem("userInfo")
      const parseLocalStorage = JSON.parse(localStorageUserToken ?? "{}") || {}
      this.getDashboardCardData(id, parseLocalStorage.session_token)
    }else{
      this.setState({ready: true, IframeURL: localStorage.getItem('trialData'), IframeName: localStorage.getItem('trialName')})
    }
  }
  getDashboardCardData = (id:string, token: string)=>{
    const webHeader = {
      'Content-Type': 'application/json',
      'token': `${token}`,
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.dashboardCardDataAPIId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/dashboard_cards/`+id,
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET");
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  getGoodData(): boolean {
    const webHeader = {
      'Content-Type': 'application/json',
    };
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.goodDataAPIId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_gooddata/embed_analyticals`,
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET");
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    return true;
  }

  async callIfram() {
    const explorerToken = localStorage.getItem('explorerToken')
    const postMessageStructure = {
      gdc: {
        product: "analyticalDesigner",
        event: {
          name: "setApiToken",
          data: {
            token: explorerToken?.replaceAll('"', '')
          }
        }
      }
    };


    this.setState({ loading: false })
    setTimeout(async () => {
      const origin = "*";
      const iframe = await this.iframRef.current.contentWindow;
      await iframe.postMessage(postMessageStructure, origin);
    }, 2500);
  }


  render() {
    return (
      <div style={{
        backgroundImage: `linear-gradient(0deg, rgb(239, 230, 240) 0%, rgb(239, 230, 240) 17%, rgb(245, 233, 246) 33%, rgb(247, 239, 244) 50%, rgb(249, 239, 242) 67%, rgb(248, 243, 247) 83%, rgb(248, 244, 252) 100%)`,
        flexDirection: "row",
        display: 'flex'
      }}>
        {this.state.ready ? 
         <>{this.state.IframeURL && this.state.IframeName ? <div style={{ overflowX: 'hidden', width: "100%", display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', top: 68 }}>
          <span style={{ fontSize: 32, fontWeight: "500", fontStyle: 'normal', marginLeft: 40, fontFamily: "Rubik", marginTop: 68 }}>{this.state.IframeName}</span>
          <iframe ref={this.iframRef} id="embedded-app-frame" src={this.state.IframeURL} style={{ display: 'flex', margin: "0px 50px", marginTop: "38px", height: '100vh', overflowX: 'hidden', borderRadius: '8px', border: 'none', }}></iframe></div>
          :
          <div style={{height: "100vh", textAlign: 'center', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <h1 style={{ color: '#6200EA', fontSize: 64, fontWeight: "500", fontStyle: 'normal', fontFamily: "Rubik" }}>404</h1>
            <h1 style={{ color: '#676B7E', fontSize: 32, fontWeight: "500", fontStyle: 'normal',  fontFamily: "Rubik"}}>Page Not Found</h1>
            </div>
            
       }</> : <div></div>}
      </div>
    )
  }

}


const webStyles = {
  menuBarStyleWrapperBottom: {
    listStyleType: 'none',
    padding: '0px',
  },
  menuBarStyleWrapperTop: {
    listStyleType: 'none',
    padding: '0px',
    marginTop: "25px",
  },
  menuBarStyle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
  },
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" }
};
